import type { IServerInfo } from '@/api/subscription';
import { getServedDevices } from '@/api/tango/server/getServedDevices/getServedDevices';
import { getPollingDetails } from '@/api/tango/server/polling/getPolingDetails/getPollingDetails';
import {
    removePolling,
    type RemovePollingParams,
} from '@/api/tango/server/polling/removePolling/removePolling';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { createServerInstanceMenuItem } from '../instanceDialogs';
import { PollingDetail } from './PollingDetail';
type PollingStatusProps = IDialogComponentProps & {
    server: IServerInfo;
};

function PollingStatus({ isOpen, close, server }: PollingStatusProps) {
    const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
    const queryClient = useQueryClient();

    const { data: devices } = useQuery({
        queryKey: ['devices'],
        queryFn: () => getServedDevices(server.name),
    });

    const { data: pollingDetails, refetch: refetchPollingStatus } = useQuery({
        queryKey: ['pollingStatus', selectedDevice],
        queryFn: () => getPollingDetails(server.name, selectedDevice ?? ''),
        enabled: !!selectedDevice,
    });

    const removePollingMutation = useMutation({
        mutationKey: ['removePolling'],
        mutationFn: (params: RemovePollingParams) => removePolling(params),
        onSuccess: () => {
            // causes the polling status to be refetched
            queryClient.invalidateQueries({
                queryKey: ['pollingStatus', selectedDevice],
            });
        },
    });
    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Polling status for: {server.name}</DialogTitle>
            <DialogContent style={{ paddingTop: '.5rem' }}>
                <Autocomplete
                    options={devices || []}
                    value={selectedDevice}
                    onChange={(_, value) => setSelectedDevice(value)}
                    renderInput={(params) => (
                        <TextField {...params} label="Device" />
                    )}
                />

                <List>
                    {selectedDevice
                        ? pollingDetails?.map((detail, index) => (
                              <PollingDetail
                                  key={index}
                                  detailText={detail}
                                  serverName={server.name}
                                  deviceName={selectedDevice}
                                  onRemove={removePollingMutation.mutate}
                              />
                          ))
                        : null}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => refetchPollingStatus()}>REFRESH</Button>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const createPollingStatus = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingStatus, null);

export default createPollingStatus;
