import { createRenameBranchQuery } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import { type IHostInfo } from '@/api/tango/database';
import { gql } from 'graphql-request';
const GET_PROPERTIES = gql`
    query GetProperties {
        devices(pattern: "tango/admin/*") {
            properties(pattern: "HostCollection") {
                name
                device
            }
        }
    }
`;
export function getProperties() {
    return gqlRequest(GET_PROPERTIES);
}

export function renameBranch(
    oldName: string,
    newBranchName: string,
    hosts: IHostInfo[],
) {
    const devices = hosts
        .filter((host) => host.branch === oldName)
        .map((host) => `tango/admin/${host.name}`);
    const query = createRenameBranchQuery(devices, newBranchName);
    return gqlRequest(query, { devices, newBranchName });
}
