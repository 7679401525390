import type { IServerInfo } from '@/api/subscription';
import { changeThreadDeviceList } from '@/api/tango/server/changeThreadDeviceList/changeThreadDeviceList';
import { getThreadInfo } from '@/api/tango/server/getThreadInfo/getThreadInfo';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';
import { useFetch } from '@/hooks/useFetch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createServerInstanceMenuItem } from '../instanceDialogs';
import ThreadsConfig from './ThreadsConfig/ThreadsConfig';

interface IPollingThreadsManagerProps extends IDialogComponentProps {
    server: IServerInfo;
}

function PollingThreadsManager({
    server,
    isOpen,
    close,
}: IPollingThreadsManagerProps) {
    const getThreadConfig = useCallback(() => getThreadInfo(server.name), []);
    const [threads, setThreads] = useState<string[]>([]);
    const { value: rawThreadConfig, loading } = useFetch(getThreadConfig);

    const threadConfig = useMemo(() => {
        if (!rawThreadConfig || !rawThreadConfig[0]?.threadConfig?.length) {
            return [];
        }
        const threadConfig = rawThreadConfig[0].threadConfig[0].value;
        if (!rawThreadConfig[0].threadNumber?.length) {
            return threadConfig;
        }
        const declaredNumberOfThreads = Number.parseInt(
            rawThreadConfig[0].threadNumber[0].value[0],
        );
        threadConfig.length = declaredNumberOfThreads;
        threadConfig.fill('', threadConfig.length); // fill the array with empty strings to match the declared number of threads
        return threadConfig;
    }, [rawThreadConfig]);

    // threadConfig serves as a default state of the threads state. Without this effect, it would be undefined.
    useEffect(() => {
        setThreads(threadConfig);
    }, [loading]);

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Manage polling threads</DialogTitle>
            <DialogContent>
                {loading ? (
                    <h1>Loading</h1>
                ) : (
                    <ThreadsConfig
                        threads={threads}
                        onChangeThreads={setThreads}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() =>
                        setThreads((prevThreads) => prevThreads.concat(''))
                    }
                >
                    ADD THREAD
                </Button>
                <Button
                    onClick={() => changeThreadDeviceList(server.name, threads)}
                >
                    SAVE
                </Button>
                <Button onClick={close}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    );
}
const createPollingThreadsManager = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingThreadsManager, null);

export default createPollingThreadsManager;
