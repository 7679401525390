import { gqlPutDeviceProperty } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

export async function changeUsage(host: string, usage: string) {
    const fullHostName = `tango/admin/${host}`;
    const property = 'HostUsage';

    const result = await gqlPutDeviceProperty(fullHostName, property, [usage]);
    const { ok } = result.putDeviceProperty;
    if (ok) {
        notifySuccess(`Changed usage for ${host}`);
    } else {
        notifyError(`Failed to change usage for ${host}`);
    }
    return result;
}
