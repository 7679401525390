import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useState } from 'react';
import './BranchOptions.scss';
type RenameBranchDialogProps = {
    open: boolean;
    onClose: () => void;
    onRenameBranch: (newBranchName: string) => void;
    branchName: string;
};

function RenameBranchDialog({
    open,
    onClose,
    onRenameBranch,
    branchName,
}: RenameBranchDialogProps) {
    const [newBranchName, setNewBranchName] = useState('');
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="branch-options-container">
                <Typography variant="h4" gutterBottom>
                    Rename branch {branchName}?
                </Typography>
                <TextField
                    value={newBranchName}
                    onChange={(event) =>
                        setNewBranchName(event.currentTarget.value)
                    }
                    placeholder="New branch name"
                />
                <Button
                    onClick={() => onRenameBranch(newBranchName)}
                    variant="contained"
                >
                    RENAME
                </Button>
            </div>
        </Dialog>
    );
}

export default RenameBranchDialog;
