import type { IServerInfo } from '@/api/subscription';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';
import type {
    IKebabMenuItem,
    TKebabMenuOnClick,
} from '@/components/KebabMenu/KebabMenu';

export interface IHostServerDialogProps extends IDialogComponentProps {
    server: IServerInfo;
}

export type TServerInstanceComponentType = ({
    server,
    isOpen,
    close,
}: IHostServerDialogProps) => JSX.Element;

export const createServerInstanceMenuItem = (
    serverInfo: IServerInfo,
    Component: TServerInstanceComponentType,
    onClick: TKebabMenuOnClick,
): IKebabMenuItem => {
    return {
        component: ({ isOpen, close }: IDialogComponentProps) => (
            <Component server={serverInfo} isOpen={isOpen} close={close} />
        ),
        onClick: onClick,
    };
};
