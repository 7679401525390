import React from 'react';

// API
import Notification from '@/components/Notification/Notification';

// Context
import {
    ChangeHostContext,
    ChangeSearchContext,
    ChangeTabContext,
    HostContext,
    SearchContext,
    TabContext,
} from '@/context/Context';
import { SessionProvider } from '@/context/Session';
import StateProvider from '@/context/StateProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// Types
import type {
    hostContextType,
    searchContextType,
    tabContextType,
} from '@/context/Context';

// React-tostify
import 'react-toastify/dist/ReactToastify.css';

// Bootstrap - but only styles that we need
import '@/assets/styles/bootstrap.scss';

// Components
import Cloud from '@/app/Cloud/Cloud';

// SCSS
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { DatabaseRouteGuard } from './DatabaseRouteGuard/DatabaseRouteGuard';
import { NotFound } from './NotFound/NotFound';

interface IAppStateProps {
    children: React.ReactElement;
}

/**
 * Component for all global contexts
 *
 * NOTE: Maybe we should use redux???
 * NOTE2: Maybe we could just use one context for these and useReducer?
 */
const AppState = ({ children }: IAppStateProps) => {
    // TODO: Create component for each
    const [search, changeSearch] = React.useState<searchContextType>({
        server: '',
        host: '',
    });
    const [host, changeHost] = React.useState<hostContextType>('Database');
    const [tab, changeTab] = React.useState<tabContextType>('Info');
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <StateProvider>
                <SessionProvider>
                    {/* Search */}
                    <SearchContext.Provider value={search}>
                        <ChangeSearchContext.Provider value={changeSearch}>
                            {/* Host */}
                            <HostContext.Provider value={host}>
                                <ChangeHostContext.Provider value={changeHost}>
                                    {/* Tab */}
                                    <TabContext.Provider value={tab}>
                                        <ChangeTabContext.Provider
                                            value={changeTab}
                                        >
                                            {children}
                                        </ChangeTabContext.Provider>
                                    </TabContext.Provider>
                                </ChangeHostContext.Provider>
                            </HostContext.Provider>
                        </ChangeSearchContext.Provider>
                    </SearchContext.Provider>
                </SessionProvider>
            </StateProvider>
        </QueryClientProvider>
    );
};

// :)
const App = () => {
    return (
        <React.StrictMode>
            <AppState>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/:tangoDb?"
                            element={
                                <DatabaseRouteGuard>
                                    <div className="app">
                                        <Notification />
                                        <Cloud />
                                    </div>
                                </DatabaseRouteGuard>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </AppState>
        </React.StrictMode>
    );
};

export default App;
