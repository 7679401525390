import { notifyError } from '@/utils/notification';
import { useDroppable } from '@dnd-kit/core';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { useState } from 'react';
import { ThreadButton } from './ThreadButton';
import ThreadDeviceItem from './ThreadDeviceItem';
type ThreadDeviceListProps = {
    threadConfig: string;
    threadName: string;
    threadIndex: number;
    onRemove: () => void;
};

export function ThreadDeviceList({
    threadConfig,
    threadName,
    threadIndex,
    onRemove,
}: ThreadDeviceListProps) {
    const [open, setOpen] = useState(false);

    const { setNodeRef } = useDroppable({
        id: threadName,
        data: {
            threadIndex,
        },
    });

    const handleRemove = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        if (threadConfig !== '') {
            notifyError('Can only remove an empty thread.');
            return;
        }
        onRemove();
    };

    return (
        <div ref={setNodeRef}>
            <ThreadButton
                onToggle={() => setOpen((currOpen) => !currOpen)}
                onRemove={handleRemove}
                threadName={threadName}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    {threadConfig.split(',').map((deviceName) => (
                        <ThreadDeviceItem
                            threadIndex={threadIndex}
                            key={deviceName}
                            deviceName={deviceName}
                        />
                    ))}
                </List>
            </Collapse>
        </div>
    );
}
