/**
 * Component which informs user that the page they are looking for does not exist
 */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './NotFound.scss';
export function NotFound() {
    return (
        <Box className="not-found">
            <Typography variant="h1">Not Found</Typography>
            <Typography variant="h3">
                The page you are looking for does not exist
            </Typography>
        </Box>
    );
}
