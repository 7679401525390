import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type ThreadButtonProps = {
    onToggle: () => void;
    threadName: string;
    onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export function ThreadButton({
    onToggle,
    threadName,
    onRemove,
}: ThreadButtonProps) {
    return (
        <ListItemButton
            onClick={onToggle}
            alignItems="center"
            sx={{ justifyContent: 'space-between' }}
        >
            <Typography variant="h5">{threadName}</Typography>
            <Tooltip title="Remove thread">
                <IconButton onClick={onRemove} color="warning" size="large">
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
        </ListItemButton>
    );
}
