import { nonEmptyString } from '@/utils/validators';
import { z } from 'zod';

export const addHostSchema = z.object({
    paths: z.set(z.string()).min(1, 'Need to provide at least one path'),
    usage: nonEmptyString,
    branch: nonEmptyString,
    name: nonEmptyString,
});

export type AddHostFormData = z.infer<typeof addHostSchema>;
