import { useDeviceData } from '@/context/DeviceData';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { getBranches } from '../DeviceList/BranchList/libBranchList';

type HostChangeBranchProps = {
    branch: string;
    onChangeBranch: (newBranch: string) => void;
    error?: string;
};

function HostChangeBranch({
    branch,
    onChangeBranch,
    error,
}: HostChangeBranchProps) {
    const [isCustomBranch, setIsCustomBranch] = useState(false);
    const { hostList } = useDeviceData();
    const branchNames = Object.keys(getBranches(hostList));
    const branchOptions = branchNames.map((branchName) => (
        <MenuItem key={branchName} value={branchName}>
            {branchName}
        </MenuItem>
    ));

    const handleChangeCustomBranch = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const newBranchName = event.currentTarget.value;
        if (newBranchName === '') {
            setIsCustomBranch(false);
        }
        onChangeBranch(newBranchName);
    };

    return (
        <FormControl style={{ width: '100%' }}>
            {isCustomBranch ? (
                <TextField
                    label="Branch name"
                    value={branch}
                    onChange={handleChangeCustomBranch}
                    error={!!error}
                    helperText={error}
                />
            ) : (
                <>
                    <Select
                        value={branch}
                        onChange={(event) => onChangeBranch(event.target.value)}
                        displayEmpty
                        renderValue={(value) =>
                            value === '' ? 'Select branch' : value
                        } // MUI Select does not support placeholder or label for empty value.
                    >
                        {branchOptions}
                        <MenuItem
                            value=""
                            onClick={() => setIsCustomBranch(true)}
                        >
                            new branch
                        </MenuItem>
                    </Select>
                </>
            )}
        </FormControl>
    );
}

export default HostChangeBranch;
