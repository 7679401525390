import { useContext } from 'react';

// Context
import { HostContext, TabContext } from '@/context/Context';

// Types
import type { ITabTemplate } from './TabNavbar/TabNavbar';

// Components
import DbInfo from '@/app/DbInfo/DbInfo';
import HostAddServer from '@/app/HostAddServer/HostAddServer';
import HostInfo from '@/app/HostInfo/HostInfo';
import HostLogs from '@/app/HostLogs/HostLogs';
import HostServers from '@/app/HostServers/HostServers';
import HostStarterLogs from '@/app/HostStarterLogs/HostStarterLogs';

import { TabNavbar } from './TabNavbar/TabNavbar';

// Bootstrap
import { Row } from 'react-bootstrap';

// SCSS
import AddHostForm from '@/app/AddHostForm/AddHostForm';
import HostEditProperties from '@/app/HostEditProperties/HostEditProperties';
import './ContentPicker.scss';

const databasebTabList: ITabTemplate = {
    'Info': { component: DbInfo },
    'Add Host': { component: AddHostForm },
};

const hostTabList: ITabTemplate = {
    'Info': { component: HostInfo },
    'Servers': { component: HostServers },
    'Add Server': { component: HostAddServer },
    'Edit Properties': { component: HostEditProperties, requireAuth: true },
    'Logs': { component: HostLogs },
    'Starter Logs': { component: HostStarterLogs, requireAuth: true },
};

/**
 * Show current picked page
 *
 * TODO: There should be routing instaed of this component
 */
const ContentPicker = () => {
    const tab = useContext(TabContext);
    const host = useContext(HostContext);

    const tabList = host === 'Database' ? databasebTabList : hostTabList;

    const CurrTab = tabList[tab].component;
    return (
        <>
            <TabNavbar tabList={tabList} />
            <Row className="contentPicker-tab">
                <CurrTab />
            </Row>
        </>
    );
};

export default ContentPicker;
