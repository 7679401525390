import { useContext, useState } from 'react';

// Types
import type { IDatabaseInfo } from '@/api/tango/database';

// Context
import { ChangeHostContext, ChangeTabContext } from '@/context/Context';

// Hooks
import { drawerContext } from '@/app/Cloud/CloudDeviceList/DeviceListDrawer/DeviceListDrawer';

// Components

// MUI
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import DeviceListItem from '../DeviceListItem/DeviceListItem';
import { DatabaseButton } from './DatabaseButton/DatabaseButton';
interface IDatabaseListProps {
    db: IDatabaseInfo[];
}

/**
 * List all databases
 *
 * NOTE: We can have more than one database but usualy
 * we use only one, that's why we display only one.
 */
const DatabaseList = ({ db }: IDatabaseListProps) => {
    const changeMobileDrawer = useContext(drawerContext);
    const changeGlobalHost = useContext(ChangeHostContext);
    const changeTab = useContext(ChangeTabContext);

    const databaseName = 'Database';
    const databaseState = db[0].state;

    const handleClick = () => {
        changeGlobalHost('Database');
        changeTab('Info');
        changeMobileDrawer(false);
        setOpen((prev) => !prev);
    };
    const [open, setOpen] = useState(false);
    return (
        <List className="deviceList-list">
            <ListItem disablePadding>
                <Stack width="100%">
                    <DeviceListItem
                        text={databaseName}
                        onClick={handleClick}
                        state={databaseState}
                    />
                    <Collapse in={open}>
                        {window?.POLKA_CONFIG?.databases?.map((database) => (
                            <DatabaseButton
                                key={database}
                                database={database}
                            />
                        ))}
                    </Collapse>
                </Stack>
            </ListItem>
        </List>
    );
};

export default DatabaseList;
