import { HOST_GET_INFO_QUERY } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { IHostGetInfoDTO } from '@/api/gql/types';

export async function getInfo(host: string) {
    const devName = `dserver/starter/${host}`;

    const response = await gqlRequest<IHostGetInfoDTO>(HOST_GET_INFO_QUERY, {
        name: devName,
    });
    return response.device;
}
