import { sleep } from 'radash';
import { useEffect } from 'react';

import { DB_GET_HOSTS_TIMING } from '@/data/constants';
import { useHostsContext } from './useHostsContext';

interface IHostProviderProps {
    children: JSX.Element;
}

const HostsProvider = ({ children }: IHostProviderProps) => {
    const { fetchHosts } = useHostsContext();

    useEffect(() => {
        const startRequestLoop = async () => {
            for (;;) {
                await sleep(DB_GET_HOSTS_TIMING);
                fetchHosts();
            }
        };
        fetchHosts();
        startRequestLoop();
    }, []);

    return children;
};

export default HostsProvider;
