// API
import { putServerInfo } from '@/api/tango/database';

// Notify
import { notifyError, notifySuccess } from '@/utils/notification';

export async function setNewLevel(
    host: string,
    serverName: string,
    controlled: string,
    level: string,
) {
    try {
        const lvl = controlled === '1' ? level : '0';

        const response = await putServerInfo(serverName, host, controlled, lvl);

        if (response) notifySuccess('Successfully changed level');
    } catch (error: unknown) {
        if (error instanceof Error) notifyError(error.message);
    }
}
