import { gqlPutDeviceProperty } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

const THREADS_NUMBER_PROPERTY = 'polling_threads_pool_size';

export async function changeNumberOfThreads(
    server: string,
    newThreadsNumber: number,
) {
    const serverDevice = `dserver/${server}`;

    const result = await gqlPutDeviceProperty(
        serverDevice,
        THREADS_NUMBER_PROPERTY,
        [newThreadsNumber.toString()],
    );
    if (!result.putDeviceProperty.ok) {
        notifyError(`Couldn't change number of threads for ${server}`);
        return;
    }
    notifySuccess(`Number of threads for ${server} set to ${newThreadsNumber}`);
    return result;
}
