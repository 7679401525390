import { gqlPutDeviceProperty } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

export async function changeBranch(host: string, branchName: string) {
    const fullHostName = `tango/admin/${host}`;
    const property = 'HostCollection';

    const result = await gqlPutDeviceProperty(fullHostName, property, [
        branchName,
    ]);
    const { ok } = result.putDeviceProperty;
    if (ok) {
        notifySuccess(`Changed branch for ${host}`);
    } else {
        notifyError(`Failed to change branch for ${host}`);
    }
    return result;
}
