// Types
import type { IHostInfo } from '@/api/tango/database';

// Create object with branches as keys and hosts array as values
export interface IBranchList {
    [branchName: string]: IHostInfo[];
}

/**
 * Group hosts by branch
 *
 * @param hostList list of hosts
 * @returns sorted key-value object where key is branch name and
 *          value is array of hosts
 */
export function getBranches(hostList: IHostInfo[]) {
    const list: IBranchList = {};
    for (const host of hostList) {
        const branchName = host.branch;

        // If branch don't exist, then create one and add current host there
        if (list[branchName] === undefined) list[branchName] = [host];
        else list[branchName].push(host);
    }
    return list;
}
