// SCSS
import './SearchTag.scss';

// Hooks
import { notifyError, notifySuccess } from '@/utils/notification';
import useSearchBar from '../SearchBar/useSearchBar/useSearchBar';

interface ISearchTagProps {
    name: string;
}

/**
 * Search tag item to represent a predefined search option
 */
const SearchTag = ({ name }: ISearchTagProps) => {
    const search = useSearchBar();

    function performSearchAndNotify(name: string) {
        try {
            const message = search(name);
            notifySuccess(message);
        } catch (error) {
            if (error instanceof Error) notifyError(error.message);
        }
    }

    return (
        <button
            key={name}
            className="searchTag-button"
            onClick={() => performSearchAndNotify(name)}
        >
            {name}
        </button>
    );
};

export default SearchTag;
