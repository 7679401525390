import { useDraggable } from '@dnd-kit/core';
import ListItemButton from '@mui/material/ListItemButton';
export type ThreadDeviceItemProps = {
    deviceName: string;
    threadIndex: number;
};

function ThreadDeviceItem({ deviceName, threadIndex }: ThreadDeviceItemProps) {
    const { listeners, setNodeRef, attributes } = useDraggable({
        id: deviceName,
        data: {
            threadIndex,
        },
    });

    return (
        <ListItemButton ref={setNodeRef} {...attributes} {...listeners}>
            {deviceName}
        </ListItemButton>
    );
}

export default ThreadDeviceItem;
