// Apollo
import { getSocketurl } from '@/utils/database';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloLink, type NormalizedCacheObject } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
export class Subscription {
    client: ApolloClient<NormalizedCacheObject>;
    constructor() {
        const wsProto = process.env.NODE_ENV === 'production' ? 'wss' : 'ws';
        const endpoint =
            `${wsProto}://` +
            window.location.host.replace('www.', '') +
            getSocketurl();

        const wsLink = new WebSocketLink({
            uri: endpoint,
            options: {
                reconnect: true,
            },
        });

        // error handling link
        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                );
            if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        // create the apollo client
        this.client = new ApolloClient({
            link: ApolloLink.from([errorLink, wsLink]),
            cache: new InMemoryCache(),
        });
    }
}
