import StorageIcon from '@mui/icons-material/Storage';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';
import './DatabaseButton.scss';

type DatabaseButtonProps = {
    database: string;
};

export function DatabaseButton({ database }: DatabaseButtonProps) {
    return (
        <Tooltip
            title={`Change tango database to ${database}`}
            placement="right"
        >
            <ListItemButton className="database-button">
                <StorageIcon className="database-button-icon" />
                <NavLink
                    to={`../${database}`}
                    reloadDocument // refreshes application state, so that the new database is loaded
                    className={({ isActive }) =>
                        isActive
                            ? `database-button-link-active`
                            : `database-button-link`
                    }
                >
                    {database}
                </NavLink>
            </ListItemButton>
        </Tooltip>
    );
}
