import { notifyError, notifySuccess } from '@/utils/notification';
import { putServerInfo } from '../../database';
import {
    startServer,
    stopServer,
} from '../../host/executHostCommand/executeHostCommand';
import { getState } from '../getState/getState';

const notifyOnError = (error: unknown) => {
    if (error instanceof Error) notifyError(error.message);
    throw error;
};

export async function changeHost(
    server: string,
    newHost: string,
    oldHost: string,
    controlled = false,
    level = 0,
) {
    const hostStarterDevice = `tango/admin/${newHost}`;
    //pinging host
    const hostStateResponse = await getState(hostStarterDevice);
    if (!hostStateResponse.executeCommand.ok) {
        notifyError(`Host ${newHost} is not running`);
        return;
    }

    const serverStateResponse = await getState(`dserver/${server}`);

    if (serverStateResponse.executeCommand.output !== 'ON') {
        notifyError(`Server ${server} is not running`);
        return;
    }
    await stopServer(oldHost, server).catch(notifyOnError);

    await startServer(newHost, server).catch(notifyOnError);

    await putServerInfo(
        server,
        newHost,
        controlled ? '1' : '0',
        level.toString(),
    ).catch(notifyOnError);

    notifySuccess(`Succesfully moved ${server} from ${oldHost} to ${newHost}`);
}
