import type { IServerInfo } from '@/api/subscription';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from '../instanceDialogs';
import ChangeHostForm from './ChangeHostForm';

function ChangeHost({ server, isOpen, close }: IHostServerDialogProps) {
    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{`Move ${server.name} to another host`}</DialogTitle>
            <DialogContent>
                <ChangeHostForm serverName={server.name} />
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    );
}

const createChangeHost = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, ChangeHost, null);

export default createChangeHost;
