import { useSession } from '@/context/Session';
import React from 'react';

type RequireAuthProps = {
    children: React.ReactNode | React.ReactNode[];
    fallback?: React.ReactNode | null;
};
function RequireAuth({
    children,
    fallback = null,
}: RequireAuthProps): JSX.Element {
    const session = useSession();
    if (!session.isLoggedIn) {
        return <>{fallback}</>;
    }
    return <>{children}</>;
}

export default RequireAuth;
