import { gqlPutDeviceProperty } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

export async function changeStartDsPath(host: string, DsPaths: string[]) {
    const fullHostName = `tango/admin/${host}`;
    const property = 'StartDsPath';

    const result = await gqlPutDeviceProperty(fullHostName, property, DsPaths);
    const { ok } = result.putDeviceProperty;
    if (ok) {
        notifySuccess(`Changed StartDSPath for ${host}`);
    } else {
        notifyError(`Failed to StartDSPath for ${host}`);
    }
    return result;
}
