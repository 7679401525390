import { useContext } from 'react';

// API
import { startServer } from '@/api/tango/host';

// Types
import type { IServerInfo } from '@/api/subscription';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from './instanceDialogs';

//Context
import { HostContext } from '@/context/Context';

// Components
import { AcceptDialog } from '@/components/Dialog/Dialog';
import { notifyError, notifySuccess } from '@/utils/notification';

const StartServer = ({ server, isOpen, close }: IHostServerDialogProps) => {
    const hostName = useContext(HostContext);

    const exec = () => {
        startServer(hostName, server.name)
            .then((response) => {
                if (response) notifySuccess('Command executed successfully');
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <AcceptDialog
            message={`Do you really want to start the ${server.name}?`}
            fn={exec}
            isOpen={isOpen}
            close={close}
        />
    );
};

const createStartServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, StartServer, null);

export default createStartServer;
