import { gqlPutDeviceProperty } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';
import { changeNumberOfThreads } from '../changeNumberOfThreads/addThread';

const THREAD_CONFIG_PROPERTY = 'polling_threads_pool_conf';

export async function changeThreadDeviceList(
    server: string,
    newThreadConfig: string[],
) {
    const serverDevice = `dserver/${server}`;
    const newThreadsConfigFormatted = newThreadConfig;

    const numberOfThreads = newThreadConfig.length;
    try {
        await changeNumberOfThreads(server, numberOfThreads);
    } catch (error) {
        notifyError(`${error}`);
        return;
    }
    const result = await gqlPutDeviceProperty(
        serverDevice,
        THREAD_CONFIG_PROPERTY,
        newThreadsConfigFormatted,
    );
    if (!result.putDeviceProperty.ok) {
        notifyError(`Couldn't change thread config for ${server}`);
        return;
    }
    notifySuccess(`Succesfully changed thread configuration for ${server}!`);
    return result;
}
