import { editHost } from '@/api/tango/editHost/editHost';
import { HostContext } from '@/context/Context';
import { useHostsList } from '@/context/HostsContext/useHostsList';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import HostChangeBranch from '../HostChangeBranch/HostChangeBranch';
import { EditHostFormSchema, type EditHostFormData } from './EditFormSchema';
import './HostEditProperties.scss';
import PathsInput from './PathsInput/PathsInput';

function HostEditProperties() {
    const hostName = useContext(HostContext);
    const hostData = useHostsList().value.find(
        (host) => host.name === hostName,
    );
    const defaultValues: EditHostFormData = {
        branch: hostData?.branch || '',
        paths: new Set<string>(hostData?.startDsPath) || [],
        usage: hostData?.usage || '',
    };

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<EditHostFormData>({
        defaultValues,
        resolver: zodResolver(EditHostFormSchema),
    });

    const onSubmit = (data: EditHostFormData) => {
        editHost(hostName, data);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }}
        >
            <Grid container className="properties-container">
                <Grid item xs={3} className="property-edit-container">
                    <Controller
                        control={control}
                        name="paths"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <PathsInput
                                paths={value}
                                onChangePaths={(newPaths) =>
                                    setValue('paths', newPaths)
                                }
                                error={errors.paths?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        {...register('usage')}
                        label={'usage'}
                        error={!!errors['usage']}
                        helperText={errors['usage']?.message}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        control={control}
                        name="branch"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <HostChangeBranch
                                branch={value}
                                onChangeBranch={(newBranch) =>
                                    setValue('branch', newBranch)
                                }
                                error={errors.branch?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="property-save-btn"
                        type="submit"
                    >
                        SAVE
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default HostEditProperties;
