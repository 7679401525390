import { LOG_LOG_AMOUNT_ON_PAGE } from '@/data/constants';
import { getCurrentDB } from '@/utils/database';
import type { IFetchLogsResponseDTO, IResponseErrorDTO } from './types';
async function handleError(response: Response) {
    const error: IResponseErrorDTO = await response.json();
    throw new Error(`Error: ${response.status} ${error.message}`);
}

/**
 * Get logs of hostname from wa-logs microservice
 *
 * @param deviceName logs of what device we are looking for
 * @param page which page we want to see
 * @param limit page size
 */
export default async function fetchLogs(
    deviceName: string,
    page: number,
    limit = LOG_LOG_AMOUNT_ON_PAGE,
) {
    const beamline = getCurrentDB();
    const tangoDB = beamline ? `&tangoDB=${beamline}` : '';

    const response = await fetch(
        `/logs/userActionLogs?device=${deviceName}${tangoDB}&limit=${limit}&page=${page}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        },
    );

    if (response.status !== 200) await handleError(response);

    const logs: IFetchLogsResponseDTO = await response.json();
    return logs;
}
