import { gqlRequest } from '@/api/gql/request';
import { notifyError } from '@/utils/notification';
import { gql } from 'graphql-request';

const DB_GET_POLLING_TARGETS_QUERY = gql`
    query getPollingTargets($device: String!) {
        targets: devices(pattern: $device) {
            commands {
                name
                intype
            }
            attributes {
                name
            }
        }
    }
`;
type TGetAttributesQuery = {
    // is empty when device is not found. In other case it should always contain State and Status.
    targets:
        | []
        | [
              {
                  // NOTE: could narrow type of intype to valid tango types.
                  commands: [{ name: string; intype: string }];
                  attributes: [{ name: string }];
              },
          ];
};

export async function getPollingTargets(deviceName: string | null) {
    const defaultValue = { commands: [], attributes: [] };
    if (!deviceName) {
        return defaultValue;
    }
    try {
        const result = await gqlRequest<TGetAttributesQuery>(
            DB_GET_POLLING_TARGETS_QUERY,
            { device: deviceName },
        );
        if (!result.targets.length) {
            notifyError(`Device ${deviceName} not found`);
            return defaultValue;
        }
        const commands = result.targets[0].commands
            .filter((command) => command.intype === 'DevVoid')
            .map((command) => command.name);
        const attributes = result.targets[0].attributes.map(
            (attr) => attr.name,
        );
        return { commands, attributes };
    } catch (error) {
        notifyError(`${error}`);
        return defaultValue;
    }
}
