import { gqlRequestCommand } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

export type PolledObjectType = 'command' | 'attribute';
export type RemovePollingParams = {
    serverName: string;
    deviceName: string;
    type: PolledObjectType;
    polledObject: string;
};

export async function removePolling({
    serverName,
    deviceName,
    type,
    polledObject,
}: RemovePollingParams) {
    const REMOVE_POLLING_COMMAND = 'RemObjPolling';
    const commandArgs = [deviceName, type, polledObject];
    const dserver = `dserver/${serverName}`;
    const result = await gqlRequestCommand(
        dserver,
        REMOVE_POLLING_COMMAND,
        commandArgs,
    );
    if (!result.executeCommand.ok) {
        notifyError(result.executeCommand.message[0]);
        return;
    }
    notifySuccess(
        `Polling updated successfully for ${deviceName}/${polledObject}`,
    );
}
