import { createDeleteBranchQuery } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import { type IHostInfo } from '@/api/tango/database';

export function deleteBranch(branchName: string, hosts: IHostInfo[]) {
    const devices = hosts
        .filter((host) => host.branch === branchName)
        .map((host) => `tango/admin/${host.name}`);
    const query = createDeleteBranchQuery(devices);
    return gqlRequest(query, { devices });
}
