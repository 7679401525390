import { deleteBranch, renameBranch } from '@/api/tango/host';
import { useDeviceData } from '@/context/DeviceData';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useState } from 'react';
import DeleteBranchDialog from './DeleteBranchDialog';
import RenameBranchDialog from './RenameBranchDialog';
type DialogType = 'Delete' | 'Rename' | 'None';

type BranchOptionsProps = {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    branchName: string;
};

function BranchOptions({
    open,
    anchorEl,
    onClose,
    branchName,
}: BranchOptionsProps) {
    const [openDialog, setOpenDialog] = useState<DialogType>('None');
    const onCloseDialog = () => setOpenDialog('None');
    const { hostList } = useDeviceData();
    const onDeleteBranch = () => {
        onCloseDialog();
        deleteBranch(branchName, hostList);
    };
    const onRenameBranch = (newBranchName: string) => {
        onCloseDialog();
        renameBranch(branchName, newBranchName, hostList);
    };

    return (
        <>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuList>
                    <MenuItem
                        onClick={() => setOpenDialog('Delete')}
                        className="branch-option"
                    >
                        <DeleteIcon color="warning" />
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => setOpenDialog('Rename')}
                        className="branch-option"
                    >
                        <EditIcon />
                        <ListItemText>Rename</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <RenameBranchDialog
                open={openDialog === 'Rename'}
                onClose={onCloseDialog}
                onRenameBranch={onRenameBranch}
                branchName={branchName}
            />
            <DeleteBranchDialog
                open={openDialog === 'Delete'}
                onClose={onCloseDialog}
                onDeleteBranch={onDeleteBranch}
                branchName={branchName}
            />
        </>
    );
}

export default BranchOptions;
