import { gqlRequestCommand } from '@/api/gql/request';
import { notifyError } from '@/utils/notification';

export async function getServedDevices(serverName: string) {
    try {
        const result = await gqlRequestCommand(
            `dserver/${serverName}`,
            'QueryDevice',
        );

        if (!result.executeCommand.ok) {
            notifyError(result.executeCommand.message[0]);
            return;
        }
        // It is array of strings in shape: TangoClass::DeviceName. The class prefix will be truncated.
        return (result.executeCommand.output as string[]).map(
            (devWithClass) => devWithClass.split('::')[1],
        );
    } catch (error) {
        notifyError(`${error}`);
        return;
    }
}
