import { getPollingTargets } from '@/api/tango/client/getPollingTargets/getPollingTargets';
import { setPolling } from '@/api/tango/server/polling/setPolling/setPolling';
import { zodResolver } from '@hookform/resolvers/zod';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import {
    PollingConfigFormSchema,
    type TPollingConfigFormSchemaIn,
    type TPollingConfigFormSchemaOut,
} from './PollingConfigForm.schema';
import './PollingConfigForm.scss';
type PollingConfigFormProps = {
    servedDevices: string[];
    serverName: string;
};

export function PollingConfigForm({
    servedDevices,
    serverName,
}: PollingConfigFormProps) {
    // We use different type for Input / Output (after validation) of the form, so that we can have a null
    //value for polledObject only during input, but before submitting.
    const { reset, control, watch, handleSubmit } = useForm<
        TPollingConfigFormSchemaIn,
        unknown,
        TPollingConfigFormSchemaOut
    >({
        defaultValues: {
            type: 'attribute',
            deviceName: null,
            polledObject: null,
            period: 3000,
        },
        resolver: zodResolver(PollingConfigFormSchema),
    });

    const deviceName = watch('deviceName');
    const { data, isFetching } = useQuery({
        queryKey: ['getPollingTargets', deviceName],
        queryFn: async () => await getPollingTargets(deviceName),
        enabled: !!deviceName,
    });

    const pollingTargetOptions =
        (watch('type') === 'attribute' ? data?.attributes : data?.commands) ||
        [];

    const onChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        // maps checked state to the type of the polled object.
        let type: TPollingConfigFormSchemaOut['type'] = 'attribute';
        if (event.target.checked) {
            type = 'command';
        }
        reset({
            deviceName: watch('deviceName'),
            polledObject: null, // resets the autocomplete value
            type,
        });
    };
    return (
        <form
            id="polling-config-form"
            aria-label="Polling config form"
            className="polling-config-form"
            noValidate
            onSubmit={handleSubmit((data) => {
                return setPolling({ ...data, serverName });
            })}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Attribute</Typography>
                <Controller
                    control={control}
                    name="type"
                    render={() => <Switch onChange={onChangeSwitch} />}
                />

                <Typography>Command</Typography>
            </Stack>
            <Controller
                control={control}
                name="deviceName"
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <Autocomplete
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="device"
                                error={!!error}
                                helperText={
                                    error ? 'Please select a valid device' : ''
                                }
                            />
                        )}
                        onChange={(_, item) => {
                            onChange(item);
                        }}
                        value={value}
                        defaultChecked={false}
                        options={servedDevices}
                    />
                )}
            />
            <Controller
                control={control}
                name="polledObject"
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <Autocomplete
                        value={value}
                        onChange={(_, item) => {
                            onChange(item);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={watch('type')}
                                error={!!error}
                                helperText={
                                    error ? 'Please select a valid target' : ''
                                }
                            />
                        )}
                        disabled={!deviceName || isFetching}
                        options={pollingTargetOptions}
                    />
                )}
            />
            <Controller
                name="period"
                control={control}
                render={({
                    field: { value, onChange },
                    fieldState: { error },
                }) => (
                    <TextField
                        value={value}
                        onChange={onChange}
                        label="Numeric Input"
                        variant="outlined"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    ms
                                </InputAdornment>
                            ),
                            inputMode: 'numeric',
                        }}
                        error={!!error}
                        helperText={error ? 'Please enter a valid number' : ''}
                    />
                )}
            />
        </form>
    );
}
