import { getCurrentDB } from '@/utils/database';

import './DatabaseRouteGuard.scss';
import { InvalidDatabaseErrorPage } from './InvalidDatabaseErrorPage/InvalidDatabaseErrorPage';
type DatabaseRouteGuardProps = {
    children: React.ReactNode;
};

export function DatabaseRouteGuard({ children }: DatabaseRouteGuardProps) {
    const currentDatabase = getCurrentDB();
    const databases = window.POLKA_CONFIG?.databases || [];
    // if there are no databases specified, we want to use only one.
    if (databases.length === 0 || databases.includes(currentDatabase)) {
        return <>{children}</>;
    }

    return (
        <InvalidDatabaseErrorPage
            databases={databases}
            currentDatabase={currentDatabase}
        />
    );
}
