import { getCommandRespond, gqlRequestCommand } from '@/api/gql/request';

import { DATABASEDS_DEV } from '@/data/constants';

/**
 * Add server to Tango database
 *
 * @param server server name
 * @param tangoClass
 * @param devices array with devices
 */
export async function addServer(
    server: string,
    tangoClass: string,
    devices: Array<string>,
) {
    const argin = [server];
    for (const dev of devices) {
        argin.push(dev);
        argin.push(tangoClass);
    }

    const response = await gqlRequestCommand(
        DATABASEDS_DEV,
        'DbAddServer',
        argin,
    );

    return getCommandRespond(response);
}
