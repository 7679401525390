import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type PathLabelProps = {
    pathName: string;
    onDelete: () => void;
};

function PathLabel({ pathName, onDelete }: PathLabelProps) {
    return (
        <ListItem>
            <ListItemText primary={pathName} />
            <ClearIcon
                onClick={onDelete}
                color="warning"
                style={{ cursor: 'pointer' }}
            />
        </ListItem>
    );
}

export default PathLabel;
