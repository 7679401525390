import { gqlRequestCommand } from '@/api/gql/request';
import { notifyError } from '@/utils/notification';

export async function getPollingDetails(serverName: string, device: string) {
    const dserver = `dserver/${serverName}`;
    const result = await gqlRequestCommand(dserver, 'DevPollStatus', device);
    if (!result.executeCommand.ok) {
        notifyError(result.executeCommand.message[0]);
        return [];
    }
    return result.executeCommand.output as string[];
}
