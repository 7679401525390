/**
 * DeviceListItem
 * Component made to provide a single styling for all buttons located in the sidebar menu.
 * It has 2 variants, one for buttons that additionally display state - i.e. Database and Branch buttons.
 * The second variant is for buttons that don't display state - i.e. Add Host button.
 */

import type { stateType } from '@/api/tango/types';
import StatusDot from '@/components/StatusDot/StatusDot';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import './DeviceListItem.scss';

type DeviceListItemProps = {
    text: string;
    onClick: () => void;
    state: stateType;
    flatRightBorder?: boolean;
};

function DeviceListItem({
    text,
    onClick,
    state,
    flatRightBorder = false,
}: DeviceListItemProps) {
    return (
        <ListItemButton
            className={`deviceListItem ${
                flatRightBorder ? 'deviceListItem-flat-border-right' : ''
            }`}
            disableGutters
            onClick={onClick}
        >
            <div className="deviceListItem-status-dot-container">
                <StatusDot state={state} size="medium" />
            </div>
            <ListItemText
                className="deviceListItem-header"
                primaryTypographyProps={{ variant: 'h6' }}
            >
                {text}
            </ListItemText>
        </ListItemButton>
    );
}

export default DeviceListItem;
