import { useContext } from 'react';

// API
import { restartServer } from '@/api/tango/host';

// Types
import type { IServerInfo } from '@/api/subscription';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from './instanceDialogs';

//Context
import { HostContext } from '@/context/Context';

// Components
import { AcceptDialog } from '@/components/Dialog/Dialog';
import { notifyError, notifySuccess } from '@/utils/notification';

const RestartServer = ({ server, isOpen, close }: IHostServerDialogProps) => {
    const hostName = useContext(HostContext);

    const exec = () => {
        restartServer(hostName, server.name)
            .then((response) => {
                if (response) notifySuccess(response);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <AcceptDialog
            message={`Do you really want to restart the ${server.name}?`}
            fn={exec}
            isOpen={isOpen}
            close={close}
        />
    );
};

const createRestartServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, RestartServer, null);

export default createRestartServer;
