import { useContext } from 'react';

// Types
import type { stateType } from '@/api/tango/types';

// Context
import { BranchListScrollContext } from '../BranchListUtils';

// Hooks
import { drawerContext } from '@/app/Cloud/CloudDeviceList/DeviceListDrawer/DeviceListDrawer';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';

// MUI
import ListItemButton from '@mui/material/ListItemButton';

// SCSS
import './HostButton.scss';

interface IHostButtonProps {
    hostName: string;
    hostState: stateType;
    hostUsage: string;
    isSearched: boolean;
    isCurrent: boolean;
    changeHost: (newHost: string, newTab: string) => void;
}

/**
 * Provide inforamtion about host and give ability to change
 * current displayed host
 *
 * @param hostName
 * @param hostState
 * @param isSearched
 * @param isCurrent indicate if this is current displayed host
 * @param changeHost change current displayed host
 */
const HostButton = ({
    hostName,
    hostState,
    hostUsage,
    isSearched,
    isCurrent,
    changeHost,
}: IHostButtonProps) => {
    const setDrawerOnMobile = useContext(drawerContext);

    // Styles
    const highlightCurrent = (isCurrent && 'hostButton-current') || '';
    const highlightSearched = (isSearched && 'hostButton-searched') || '';

    // Functions
    const handleClick = () => {
        changeHost(hostName, 'Servers');
        setDrawerOnMobile(false);
    };

    const scroll = useContext(BranchListScrollContext);
    const scrollSetNode = (node: HTMLDivElement | null) => {
        if (node) scroll.addHostNode(node, hostName);
    };

    return (
        <ListItemButton
            id={hostName}
            ref={scrollSetNode}
            className={`hostButton ${highlightCurrent} ${highlightSearched}`}
            onClick={handleClick}
        >
            <div className="hostButton-main-info-container">
                <div>
                    <StatusDot state={hostState} size="small" />
                    <span className="hostButton-name">{hostName}</span>
                </div>
                <p className="hostButton-usage-text">{hostUsage}</p>
            </div>
        </ListItemButton>
    );
};

export default HostButton;
