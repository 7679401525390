import { gql } from 'graphql-request';

// TODO: think about moving all queries with corresponding type declarations to directories
// corresponding to particular api calls.

/* ====== REQUEST ====== */

export const REQ_EXECUTE_COMMAND_QUERY = gql`
    mutation ExecuteCommand(
        $device: String!
        $command: String!
        $argin: ScalarTypes
    ) {
        executeCommand(device: $device, command: $command, argin: $argin) {
            ok
            message
            output
        }
    }
`;

export const REQ_PUT_DEVICE_PROPERTY_QUERY = gql`
    mutation putDeviceProperty(
        $device: String!
        $name: String!
        $value: [String]!
    ) {
        putDeviceProperty(device: $device, name: $name, value: $value) {
            ok
            message
        }
    }
`;

/* ====== DATABASE ====== */

export const DB_GET_INFO_STRING_QUERY = gql`
    query {
        info
    }
`;

export const DB_GET_ALL_DB_QUERY = gql`
    query {
        database: devices(pattern: "sys/database/*") {
            name
            state
        }
    }
`;

export const DB_GET_HOSTS_QUERY = gql`
    query {
        hosts: devices(pattern: "tango/admin/*") {
            name
            state
            branch: properties(pattern: "HostCollection") {
                value
            }
            usage: properties(pattern: "HostUsage") {
                value
            }
            startDsPath: properties(pattern: "StartDsPath") {
                value
            }
        }
    }
`;

export const DB_GET_SERVERS_QUERY = gql`
    query {
        allServers: servers {
            instances {
                name
                server
                classes(pattern: "dserver") {
                    devices {
                        state
                        server {
                            host
                        }
                    }
                }
            }
        }
    }
`;

export const DB_GET_SERVER_THREAD_INFO_QUERY = gql`
    query getServerThreadInfo($server: String!) {
        devices(pattern: $server) {
            threadConfig: properties(pattern: "polling_threads_pool_conf") {
                value
            }
            threadNumber: properties(pattern: "polling_threads_pool_size") {
                value
            }
        }
    }
`;

/* ====== TANGO HOST ====== */

export const HOST_GET_SERVERS_QUERY = gql`
    query StarterGetServers($atr: [String]!) {
        servers: attributes(fullNames: $atr) {
            value
        }
    }
`;

export const HOST_GET_INFO_QUERY = gql`
    query Dev($name: String!) {
        device(name: $name) {
            name
            state
            connected
            server {
                id
                host
            }
            pid
            startedDate
            stoppedDate
            exported
        }
    }
`;

const generateBranchChangeQuery = (
    device: string,
    index: number,
    newBranchName: string,
) => `
    putDeviceProperty${index}: putDeviceProperty(device: "${device}", name: "HostCollection", value: "${newBranchName}") {
        ok
        message
}`;
// Branch info is stored inside device properties of hosts. Therefore to rename a branch, we need to change the value of the
// property "HostCollection" of each host. TangoGQL does not allow for change of device property in multiple devices at once
// so we need to either create a bunch of requests, or bundle mulitple requests into one query. This function utilizes the latter approach.
export const createRenameBranchQuery = (
    devices: string[],
    newBranchName: string,
) => gql`
    mutation renameBranch{
    ${devices
        .map((device, index) =>
            generateBranchChangeQuery(device, index, newBranchName),
        )
        .join(' ')}
    }
`;

// TODO: change naming of some of these functions above!!!

const generateDeleteBranchQuery = (device: string, index: number) => {
    return `
        deleteBranch${index}: deleteDeviceProperty(device: "${device}", name: "HostCollection") {
            ok
            message
        }
    `;
};
export const createDeleteBranchQuery = (devices: string[]) => gql`
    mutation deleteBranch{
    ${devices.map(generateDeleteBranchQuery).join(' ')}
    }
`;

/* ====== CLIENT ====== */

export const CLIENT_PING_QUERY = gql`
    query Ping($dev: String!) {
        device(name: $dev) {
            attributes(pattern: "state") {
                value
            }
        }
    }
`;

/* SUBSCRIPTION */

export const HOST_SERVERS_SUBSCRIPTION_QUERY = gql`
    subscription ServerSubscription($hosts: [String]!) {
        servers: attributes(fullNames: $hosts) {
            value
        }
    }
`;
