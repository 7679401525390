import { gqlRequest } from '@/api/gql/request';

import { DB_GET_HOSTS_QUERY } from '@/api/gql/query';

// Types
import type { IDatabaseGetHostsDTO } from '@/api/gql/types';
import type { stateType } from '@/api/tango/types';

import { getAllHostsCreateReadable } from './libGetAllHosts';
export interface IHostInfo {
    name: string;
    state: stateType;
    branch: string;
    usage: string;
    startDsPath: string[];
}

// Get array of all host
export async function getAllHosts(): Promise<IHostInfo[]> {
    const response = await gqlRequest<IDatabaseGetHostsDTO>(DB_GET_HOSTS_QUERY);

    return getAllHostsCreateReadable(response);
}
