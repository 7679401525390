// API
import * as Database from '@/api/tango/database';
import { notifyError, notifySuccess } from '@/utils/notification';

// Validate each device string
function validateDevice(deviceString: string) {
    const deviceNameArray = deviceString.split('/');

    // In Tango, device name consist of 3 parts:
    // device/family/member
    const DEV_PART = 3;

    // Here we check if they exist
    if (deviceNameArray.length !== DEV_PART)
        throw new Error(`Bad device name: ${deviceString}`);

    // device, family, member
    const [d, f, m] = deviceNameArray;
    const device = {
        domain: d,
        family: f,
        member: m,
    };

    // Check if all parts isn't empty
    for (const [type, value] of Object.entries(device))
        if (!value.length) throw new Error(`Bad ${type} of ${deviceString}`);
    return true;
}

// Validate all inputs, throw exception on error
function validateAll(
    server: string,
    tangoClass: string,
    deviceArray: Array<string>,
) {
    const inputs = {
        'server': server,
        'tango class': tangoClass,
        'devices': deviceArray,
    };

    // Check if user typed to each field
    for (const [type, value] of Object.entries(inputs))
        if (!value.length) throw new Error(`${type} field is empty`);

    // Check if devices names is valid
    for (const dev of deviceArray) validateDevice(dev);
}

export async function startNew(
    server: string,
    tangoClass: string,
    devicesString: string,
) {
    try {
        const deviceArray = devicesString.split('\n');
        validateAll(server, tangoClass, deviceArray);

        const response = await Database.addServer(
            server,
            tangoClass,
            deviceArray,
        );
        if (response) notifySuccess(`Successfully created ${server}`);
    } catch (error: unknown) {
        if (error instanceof Error) notifyError(error.message);
    }
}
