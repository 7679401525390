import { nonEmptyString } from '@/utils/validators';
import { z } from 'zod';

export const EditHostFormSchema = z.object({
    paths: z.set(z.string()).min(1, 'Need to provide at least one path'),
    usage: z.string(), // Can be empty!
    branch: nonEmptyString,
});

export type EditHostFormData = z.infer<typeof EditHostFormSchema>;
