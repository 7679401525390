import { DB_GET_SERVER_THREAD_INFO_QUERY } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { IDatabaseThreadInfoDTO } from '@/api/gql/types';

export async function getThreadInfo(serverName: string) {
    const serverDevice = `dserver/${serverName}`;
    const result = await gqlRequest<IDatabaseThreadInfoDTO>(
        DB_GET_SERVER_THREAD_INFO_QUERY,
        { server: serverDevice },
    );
    return result.devices;
}
