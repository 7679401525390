import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import './BranchOptions.scss';
type DeleteBranchDialogProps = {
    open: boolean;
    onClose: () => void;
    onDeleteBranch: () => void;
    branchName: string;
};

function DeleteBranchDialog({
    open,
    onClose,
    onDeleteBranch,
    branchName,
}: DeleteBranchDialogProps) {
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="branch-options-container">
                <Typography variant="h4" gutterBottom>
                    Delete branch {branchName}?
                </Typography>
                <Button
                    onClick={onDeleteBranch}
                    variant="contained"
                    color="warning"
                >
                    DELETE
                </Button>
            </div>
        </Dialog>
    );
}

export default DeleteBranchDialog;
