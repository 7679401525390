import {
    DndContext,
    DragOverlay,
    type DragEndEvent,
    type DragStartEvent,
} from '@dnd-kit/core';
import List from '@mui/material/List';
import { useState } from 'react';
import ThreadDeviceItem, {
    type ThreadDeviceItemProps,
} from './ThreadDeviceItem';
import { ThreadDeviceList } from './ThreadDeviceList';

type ThreadsConfigProps = {
    threads: string[];
    onChangeThreads: (newThreads: string[]) => void;
};

function ThreadsConfig({ threads, onChangeThreads }: ThreadsConfigProps) {
    const [draggedDevice, setDraggedDevice] =
        useState<ThreadDeviceItemProps | null>(null);

    const addDeviceToThread = (threadIndex: number, deviceName: string) => {
        const thread = threads[threadIndex]
            .split(',')
            .concat(deviceName)
            .join(',');
        const newThreads = structuredClone(threads);
        newThreads[threadIndex] = thread;
        onChangeThreads(newThreads);
        setDraggedDevice(null);
    };
    const handleDragEnd = (event: DragEndEvent) => {
        if (event.over === null) {
            const threadIndex = draggedDevice?.threadIndex as number;
            const deviceName = draggedDevice?.deviceName as string;
            addDeviceToThread(threadIndex, deviceName);
            return;
        }
        // dnd-kit library does not support generic types to provide for the data property.
        const threadIndex = event.over?.data.current?.threadIndex as number;
        const deviceName = event.active.id as string;
        addDeviceToThread(threadIndex, deviceName);
    };

    const handleRemoveThread = (threadIndex: number) => () => {
        const newThreads = structuredClone(threads);
        newThreads.splice(threadIndex, 1);
        onChangeThreads(newThreads);
    };

    const handleDragStart = (event: DragStartEvent) => {
        // The thread configuration is stored as an array of comma-separated strings.
        // Each string represents a thread and contains a list of devices, that perform polling on said thread.
        // This function removes the device from the thread, when it is dragged.
        const threadIndex = event.active.data.current?.threadIndex as number;
        const deviceName = event.active.id as string;
        const thread = threads[threadIndex];
        const deviceIndex = thread.split(',').indexOf(deviceName);
        const newThread = thread.split(',');
        newThread.splice(deviceIndex, 1);
        const newThreads = structuredClone(threads);
        newThreads[threadIndex] = newThread.join(',');
        onChangeThreads(newThreads);
        setDraggedDevice({ deviceName, threadIndex });
    };

    return (
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <List>
                {threads.map((threads, index) => (
                    <ThreadDeviceList
                        key={`Thread ${index + 1}`}
                        threadConfig={threads}
                        threadName={`Thread ${index + 1}`}
                        threadIndex={index}
                        onRemove={handleRemoveThread(index)}
                    />
                ))}
            </List>
            <DragOverlay>
                {draggedDevice ? <ThreadDeviceItem {...draggedDevice} /> : null}
            </DragOverlay>
        </DndContext>
    );
}

export default ThreadsConfig;
