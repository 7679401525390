import type { AddHostFormData } from '@/app/AddHostForm/AddHostFormSchema';
import { notifyError, notifySuccess } from '@/utils/notification';
import { addServer } from '../../database/addBranch/addBranch';
import { changeBranch } from '../branch/changeBranch/changeBranch';
import { changeStartDsPath } from '../properties/changeStartDsPath';
import { changeUsage } from '../properties/changeUsage';

export async function addHost({ name, branch, usage, paths }: AddHostFormData) {
    const tangoClass = 'Starter';
    const serverName = `${tangoClass}/${name}`;
    const device = `tango/admin/${name}`;
    const result = await addServer(serverName, tangoClass, [device]);
    if (result.ok) {
        notifySuccess(`Succesfully created host: ${name} `);
    } else {
        notifyError(`Failed to create host: ${name}`);
    }
    await changeBranch(name, branch);
    await changeUsage(name, usage);
    await changeStartDsPath(name, Array.from(paths));
    return result;
}
