import { z } from 'zod';

export const PollingConfigFormSchema = z.object({
    deviceName: z.string().min(1, 'Device name is required'),
    polledObject: z.string().min(1, 'Polled object is required'),
    period: z.coerce.number().int().min(1, 'Period should be greater than 0'),
    type: z.enum(['attribute', 'command']),
});

export type TPollingConfigFormSchemaOut = z.infer<
    typeof PollingConfigFormSchema
>;
export type TPollingConfigFormSchemaIn = Omit<
    TPollingConfigFormSchemaOut,
    'polledObject' | 'deviceName'
> & {
    polledObject: TPollingConfigFormSchemaOut['polledObject'] | null;
    deviceName: TPollingConfigFormSchemaOut['deviceName'] | null;
};
