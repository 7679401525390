import type { EditHostFormData } from '@/app/HostEditProperties/EditFormSchema';
import { changeBranch, changeStartDsPath, changeUsage } from '../host';

export async function editHost(
    name: string,
    { branch, usage, paths }: EditHostFormData,
) {
    await changeBranch(name, branch);
    await changeUsage(name, usage);
    await changeStartDsPath(name, Array.from(paths));
}
