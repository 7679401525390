import { useContext } from 'react';

// API
import * as TangoHost from '@/api/tango/host';

// Types
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';

//Context
import { HostContext } from '@/context/Context';

// Components
import { notifyError, notifySuccess } from '@/utils/notification';

// MUI
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export interface IStartAllProps extends IDialogComponentProps {
    level: string;
}

export const StartAll = ({ level, isOpen, close }: IStartAllProps) => {
    const hostName = useContext(HostContext);

    const exec = () => {
        TangoHost.startLevel(hostName, level)
            .then((resp) => {
                if (resp) notifySuccess(`Successfully started level ${level}`);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Do you really want to start all servers?</DialogTitle>
            <DialogActions>
                <Button onClick={exec}>OK</Button>
                <Button onClick={close}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default StartAll;
