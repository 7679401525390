// Components

// Bootstrap
import { Container } from 'react-bootstrap';

//SCSS
import './Cloud.scss';
import CloudBody from './CloudBody/CloudBody';

const Cloud = () => {
    return (
        <Container className="cloud">
            <CloudBody />
        </Container>
    );
};

export default Cloud;
