import type React from 'react';
import { createContext } from 'react';
const defaultDispatch = () => null;

// TODO: Put contexts in separate files

// TODO: We can put host and tab in one context

/* ====== HOST ====== */
// Current host or starter if you like //

export type hostContextType = string;
export type changeHostContextType = React.Dispatch<hostContextType>;

export const HostContext = createContext<hostContextType>('');
export const ChangeHostContext =
    createContext<changeHostContextType>(defaultDispatch);

/* ====== TAB ====== */

export type tabContextType = string;
export type changeTabContextType = React.Dispatch<tabContextType>;

export const TabContext = createContext<tabContextType>('');
export const ChangeTabContext =
    createContext<changeTabContextType>(defaultDispatch);

/* ====== SEARCH ====== */
// Current server we're looking for //

export type searchContextType = { server: string; host: string };
export type changeSearchContextType = React.Dispatch<searchContextType>;

export const SearchContext = createContext<searchContextType>({
    server: '',
    host: '',
});
export const ChangeSearchContext =
    createContext<changeSearchContextType>(defaultDispatch);

/* ====== DATABASE ====== */
export type databaseContextType = {
    database: string;
    changeDatabase: React.Dispatch<string>;
};
