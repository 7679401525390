import { useContext, useRef } from 'react';
import useChangeHost from './useChangeHost';

// Types
import type { IHostInfo } from '@/api/tango/database';

// Context
import { HostContext, SearchContext } from '@/context/Context';
import { BranchListScrollContext } from '../BranchListUtils';

// Components
import HostButton from '../HostButton/HostButton';

// MUI
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

interface IBranchHostListProps {
    branchHostList: IHostInfo[];
    isListOpen: boolean;
    isHostSearchedHere: boolean;
}

/**
 * List of all host in branch
 *
 * @param branchHostList list of hosts to display
 * @param isListOpen
 * @param isHostSearchedHere
 */
const BranchHostList = ({
    branchHostList,
    isListOpen,
    isHostSearchedHere,
}: IBranchHostListProps) => {
    const hostName = useContext(HostContext);
    const changeHostFn = useChangeHost();
    const { host: searchedHost } = useContext(SearchContext);

    const didListCollapsed = useRef(false);
    const toogleCollapseIndicator = () =>
        (didListCollapsed.current = !didListCollapsed.current);

    const scroll = useContext(BranchListScrollContext);
    if (isHostSearchedHere && didListCollapsed)
        scroll.scrollToNode(searchedHost);

    return (
        <Collapse
            in={isListOpen}
            timeout="auto"
            addEndListener={toogleCollapseIndicator}
        >
            <List component="div">
                {branchHostList.map((host) => {
                    const isCurrent = hostName === host.name;
                    const isSearched = searchedHost === host.name;

                    return (
                        <HostButton
                            key={host.name}
                            hostName={host.name}
                            hostState={host.state}
                            hostUsage={host.usage}
                            isCurrent={isCurrent}
                            isSearched={isSearched}
                            changeHost={changeHostFn}
                        />
                    );
                })}
            </List>
        </Collapse>
    );
};

export default BranchHostList;
