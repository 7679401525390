import {
    type PolledObjectType,
    type RemovePollingParams,
} from '@/api/tango/server/polling/removePolling/removePolling';
import { AcceptDialog } from '@/components/Dialog/Dialog';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

type PollingDetailProps = {
    detailText: string;
    serverName: string;
    deviceName: string;
    onRemove: (params: RemovePollingParams) => void;
};

export function PollingDetail({
    detailText,
    serverName,
    deviceName,
    onRemove,
}: PollingDetailProps) {
    /**
     * PollingDetail component.
     * It displays the information about the polling status of an attribute or command.
     * The way that these details are extracted, it makes it difficult to identify name and type of polled object
     * hence, these details are extracted using pattern matching of the detail string.
     * @param detailText - the text that contains the polling details.
     *      Formatted like this: "Polled {type} name = {polledObject}\n {rest of the details, separated by newlines}"
     */
    const [listOpen, setListOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [polledObjectInfo, ...details] = detailText.split('\n');
    const parseObjectInfo = (objectInfo: string) => {
        // extract type and polledObject from the string.
        // The first element of the result is sliced, because it contains the whole string.
        const [type, polledObject] =
            objectInfo.match(/Polled (\w+) name = (\w+)/)?.slice(1) ?? [];
        return { type, polledObject };
    };

    const { type, polledObject } = parseObjectInfo(polledObjectInfo);
    return (
        <>
            <ListItemButton onClick={() => setListOpen((isOpen) => !isOpen)}>
                <Typography variant="h5">
                    {type} {polledObject}
                </Typography>
            </ListItemButton>
            <Collapse in={listOpen}>
                {details.map((detail, index) => (
                    <ListItem key={index}>{detail}</ListItem>
                ))}
                <ListItem>
                    <Button
                        onClick={() => setDialogOpen(true)}
                        variant="contained"
                        color="warning"
                    >
                        REMOVE
                    </Button>
                </ListItem>
            </Collapse>
            <AcceptDialog
                message={`Do you really want to remove polling for ${type} ${polledObject}?`}
                fn={() =>
                    onRemove({
                        serverName,
                        deviceName,
                        type: type as PolledObjectType,
                        polledObject,
                    })
                }
                isOpen={dialogOpen}
                close={() => setDialogOpen(false)}
            />
        </>
    );
}
