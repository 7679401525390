// Types
import type { IServerInfo } from '@/api/subscription';
import type { IMenuOptions } from '@/components/KebabMenu/KebabMenu';

import createConfigureWithTaranta from '../InstanceDialogs/ConfigureWithTaranta';
import createKillServer from '../InstanceDialogs/KillServer';
import createChangeHost from '../InstanceDialogs/MoveToHost/ChangeHost';
import createPollingConfigurator from '../InstanceDialogs/PollingConfigurator/PollingConfigurator';
import createPollingStatus from '../InstanceDialogs/PollingStatus/PollingStatus';
import createPollingThreadsManager from '../InstanceDialogs/PollingThreadsManager/PollingThreadsManager';
import createRestartServer from '../InstanceDialogs/RestartServer';
import createSetStartupLevel from '../InstanceDialogs/SetStartupLevel/SetStartupLevel';
import createStartServer from '../InstanceDialogs/StartServer';

function configureWithTaranta(
    serverInfo: IServerInfo,
    menuOptions: IMenuOptions,
) {
    const taranta_url = window.POLKA_CONFIG?.taranta_url ?? '';

    if (!taranta_url) return menuOptions;

    menuOptions['configure With Taranta'] =
        createConfigureWithTaranta(serverInfo);

    return menuOptions;
}

export function createMenuOptions(serverInfo: IServerInfo) {
    const menuOptions: IMenuOptions = {
        'start server': createStartServer(serverInfo),
        'kill server': createKillServer(serverInfo),
        'restart server': createRestartServer(serverInfo),
        'set startup level': createSetStartupLevel(serverInfo),
        'configure polling': createPollingConfigurator(serverInfo),
        'polling status': createPollingStatus(serverInfo),
        'polling threads manager': createPollingThreadsManager(serverInfo),
        'move to different host': createChangeHost(serverInfo),

        // TODO:
        //"server info":
        //"class info":
        //"device dependencies": ?
        //"test device with Taranta":
        //"starter logs":
    };
    configureWithTaranta(serverInfo, menuOptions);

    return menuOptions;
}
