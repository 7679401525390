export const getCurrentDB = () => window.location.pathname.split('/')[1];

export const getApiUrl = () => {
    const tangoDb = getCurrentDB();
    return `${tangoDb ? `/${tangoDb}` : ''}/db`;
};

export const getSocketurl = () => {
    const tangoDb = getCurrentDB();
    return `${tangoDb ? `/${tangoDb}` : ''}/socket`;
};

export const getTarantaUrl = () => {
    const tangoDb = getCurrentDB();
    const taranta_url = window.POLKA_CONFIG?.taranta_url ?? '';

    return `${taranta_url}/${tangoDb}`;
};
