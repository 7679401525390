import { z } from 'zod';

export const ChangeHostSchema = z.object({
    hostName: z.string().min(1, "Host name can't be empty."),
    controlled: z.boolean(),
    level: z
        .number()
        .int()
        .min(0, "Level can't be less than 0.")
        .max(5, "Level can't be more than 5."),
});

export type ChangeHostFormData = z.infer<typeof ChangeHostSchema>;
