import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

type InvalidDatabaseErrorPageProps = {
    databases: string[];
    currentDatabase: string;
};

export function InvalidDatabaseErrorPage({
    databases,
    currentDatabase,
}: InvalidDatabaseErrorPageProps) {
    return (
        <div className="RouteChecker-error-page">
            <Typography variant="h3">
                Invalid database: {currentDatabase}
            </Typography>
            <Typography variant="h4">
                These databases are defined in the app config:
            </Typography>
            <List>
                {databases.map((db) => (
                    <div>
                        <Link href={`../${db}`} variant="h5" key={db}>
                            {db}
                        </Link>
                    </div>
                ))}
            </List>
            <Typography variant="h6">
                To select a valid database please append it to the end of the
                URL. So that it looks like: {window.location.origin}/database
            </Typography>
        </div>
    );
}
