import { gqlRequestCommand } from '@/api/gql/request';
import { notifyError, notifySuccess } from '@/utils/notification';

export type SetPollingParams = {
    serverName: string;
    period: number; // in milliseconds
    type?: 'attribute' | 'command';
    deviceName: string;
    polledObject: string;
};

async function addOrUpdatePolling(
    dserver: string,
    command: 'UpdObjPollingPeriod' | 'AddObjPolling',
    commandArgs: (string[] | number[])[],
) {
    // errors may occur in 2 ways, fetch requests, such as 401 unauthorized will throw an error.
    // TangoGQL errors must be caught in a different way. They are returned in the response object.
    try {
        return await gqlRequestCommand(dserver, command, commandArgs);
    } catch (error) {
        notifyError(`${error}`);
        return;
    }
}

export async function setPolling({
    period,
    serverName,
    type = 'attribute',
    deviceName,
    polledObject,
}: SetPollingParams) {
    // adds polling configuration for a specific command / attribute. If it already exists - it updates the polling period.
    const UPDATE_POLLING_COMMAND = 'UpdObjPollingPeriod';
    const ADD_POLLING_COMMAND = 'AddObjPolling';
    const commandArgs = [[period], [deviceName, type, polledObject]];
    const dserver = `dserver/${serverName}`;

    const addPollingResult = await addOrUpdatePolling(
        dserver,
        ADD_POLLING_COMMAND,
        commandArgs,
    );
    if (!addPollingResult) return;

    if (
        addPollingResult.executeCommand.message.length >= 2 &&
        addPollingResult.executeCommand.message[1] === 'API_AlreadyPolled'
    ) {
        const updatePollingResult = await addOrUpdatePolling(
            dserver,
            UPDATE_POLLING_COMMAND,
            commandArgs,
        );
        if (!updatePollingResult) return;
        if (!updatePollingResult.executeCommand.ok) {
            notifyError(updatePollingResult.executeCommand.message[0]);
            return;
        }
    } else if (!addPollingResult.executeCommand.ok) {
        notifyError(addPollingResult.executeCommand.message[0]);
        return;
    }
    notifySuccess(
        `Polling updated successfully for ${deviceName}/${polledObject}`,
    );
}
